import React from "react"
import { Flex, Text, Box } from "@chakra-ui/react"
import CopyButton from "./CopyButton"

const Emoji = ({ emoji, description }) => {
  const titleCase = (str) => {
    str = str.toLowerCase().split(" ")
    for (var i = 0; i < str.length; i++) {
      str[i] = str[i].charAt(0).toUpperCase() + str[i].slice(1)
    }
    return str.join(" ")
  }

  return (
    <Box my="2vh">
      <Flex>
        <Box
          as="button"
          borderRadius="lg"
          bg="white"
          color="white"
          px={4}
          h={10}
        >
          <Text fontSize="2xl">{emoji}</Text>
        </Box>
        <CopyButton description={titleCase(description)} emoji={emoji} />

        <Box ml="2vh">
          <Text fontSize="xl" fontWeight="bold">
            {titleCase(description)}
          </Text>
        </Box>
      </Flex>
    </Box>
  )
}

export default Emoji
