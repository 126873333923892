import React from "react"
import { ChakraProvider } from "@chakra-ui/react"
import Layout from "./Layout"
import { getInitialData } from "../utils/data"
import autoBindReact from "auto-bind/react"

// const App = () => {
//   return (
//     <ChakraProvider>
//       <Layout />
//     </ChakraProvider>
//   )
// }

class App extends React.Component {
  constructor(props) {
    super(props)

    // todo load emoji data
    let data = getInitialData()

    this.state = {
      emoji: data,
      userInput: "",
    }

    // todo bindings
    autoBindReact(this)
  }

  onInputTextHandler(e) {
    this.setState({
      userInput: e.target.value,
    })
  }

  render() {
    return (
      <ChakraProvider>
        <Layout
          data={this.state.emoji}
          onInputTextHandler={this.onInputTextHandler}
          userInput={this.state.userInput}
        />
      </ChakraProvider>
    )
  }
}

export default App
