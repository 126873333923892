import React from "react"
import { Box, Container, Heading, Center } from "@chakra-ui/react"
import InputText from "./InputText"
import EmojiList from "./EmojiList"

const Layout = ({ onInputTextHandler, data, userInput }) => {
  return (
    <Container>
      <Center>
        <Box p="1" my="2vh">
          <Heading size="lg">Emoji Finder</Heading>
        </Box>
      </Center>
      <InputText onInputTextHandler={onInputTextHandler} />
      <EmojiList data={data} userInput={userInput} />
    </Container>
  )
}

export default Layout
