import React from "react"
import { Button, useToast, useClipboard } from "@chakra-ui/react"
import { CopyIcon } from "@chakra-ui/icons"

const CopyButton = ({ description, emoji }) => {
  const toast = useToast()
  const { onCopy } = useClipboard(emoji)

  const showToast = () => {
    toast({
      title: `${emoji} ${description} Copied`,
      status: "success",
      duration: 800,
      isClosable: true,
    })

    // copy to clipboard
    onCopy()
  }

  return (
    <Button onClick={showToast}>
      <CopyIcon />
    </Button>
  )
}

export default CopyButton
