import React from "react"
import { Flex, Input } from "@chakra-ui/react"

const InputText = ({ onInputTextHandler }) => {
  return (
    <Flex>
      <Input
        onChange={onInputTextHandler}
        placeholder="What emoji are you looking for? "
        autoFocus
      />
    </Flex>
  )
}

export default InputText
