import { Box, Center } from "@chakra-ui/react"
import React from "react"
import Emoji from "./Emoji"

const EmojiList = ({ data, userInput }) => {
  let result = data
    .filter((x) => x.description.toLowerCase().match(userInput.toLowerCase()))
    .slice(0, 40)
  return (
    <Center>
      <Box m="2vh">
        {result.map((x) => (
          <Emoji
            key={x.description}
            emoji={x.emoji}
            keyword={x.keyword}
            description={x.description}
          />
        ))}
      </Box>
    </Center>
  )
}

export default EmojiList
